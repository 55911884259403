import React, { useState, useEffect, useRef } from 'react';
import './Chat.css';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

function Chat() {
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // Introduced as a useRef to persist the value across re-renders without causing additional renders
  const greetingFetched = useRef(false);
  const chatOutputRef = useRef(null);


  let baseUrl = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5000' : '';

  // Added useEffect hook to clear chat history on component mount
  useEffect(() => {
    const clearHistory = async () => {
      try {
        const response = await fetch(`${baseUrl}/clear_history`, {
          method: 'POST',
          credentials: 'include', // Make sure credentials are included for session cookies
        });
        const data = await response.json();
        console.log(data.message); // Log the response from the server
      } catch (error) {
        console.error('Error clearing chat history:', error);
      }
    };

    clearHistory();
  }, []);
  useEffect(() => {
    console.log("Component mounted or updated");
    if (!greetingFetched.current) { // Use .current to access the ref value
      console.log("Fetching greeting...");
      requestBotGreeting();
      greetingFetched.current = true; // Update the ref directly without causing a re-render
    }
  }, []); // Empty dependency array to mimic componentDidMount behavior

  const requestBotGreeting = () => {
    setIsLoading(true);
    fetch(`${baseUrl}/init`)
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
        addMessage('bot', data.answer);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
        addMessage('error', 'Error getting greeting from the server.');
      });
  };

  const addMessage = (sender, text) => {
    setMessages(messages => [...messages, { sender, text }]);
    setTimeout(() => {
      if (chatOutputRef.current) {
        chatOutputRef.current.scrollTop = chatOutputRef.current.scrollHeight;
      }
    }, 100);
  };

  const sendMessage = () => {
    const message = userInput.trim();
    if (!message) return;

    setIsLoading(true);
    addMessage('user', message);
    setUserInput('');

    fetch(`${baseUrl}/chat`, {
      method: 'POST',
      credentials: 'include', // Important for sessions
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
        addMessage('bot', data.answer);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error:', error);
        addMessage('error', 'Error getting response from the server.');
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <div id="chat-container">
      <div id="chat-box">
        <div id="chat-title">Assistant Artis</div>
        <div id="chat-output" ref={chatOutputRef}>
          {messages.map((msg, index) => {
            // Use `marked` to convert Markdown in msg.text to HTML
            const markup = { __html: DOMPurify.sanitize(marked(msg.text)) };

            return (
              <div key={index} className={`message ${msg.sender}-message`} dangerouslySetInnerHTML={markup}></div>
            );
          })}
          {isLoading && (
            <div className="message bot-message">
              <div className="loading-bubble"></div>
            </div>
          )}
        </div>
        <div id="user-input-container">
          <textarea
            id="user-input"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Écrivez votre message..."
          ></textarea>
          <button id="send-btn" onClick={sendMessage}>Envoyer</button>
        </div>
      </div>
    </div>
  );
}

export default Chat;
