import React from 'react';
import Chat from './chat';

function App() {
    return (
      <div>
        <Chat />
      </div>
    );
  }
  
  export default App;